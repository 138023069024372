<template>
  <section>
    <div class="columns is-multiline">
      <div class="column is-12">
        <p v-if="hasData">Please enter the current mileage (if known).</p>
        <p v-else>
          <span>Please enter a vehicle </span>
          <span class="has-text-soft has-text-weight-semibold">* </span>
          <span class="has-text-weight-semibold">VRM and/or VIN</span>
          <span class="has-text-soft has-text-weight-semibold"> *</span>
          <span>, and current mileage (if known).</span>
        </p>
      </div>
      <div class="column">
        <label class="has-text-weight-semibold">VRM</label>
        <input
          class="input is-size-6-mobile is-uppercase"
          v-model.trim="vrm"
          type="text"
          :disabled="hasData"
          name="vrm"
        />
      </div>
      <div class="column">
        <label class="has-text-weight-semibold">VIN</label>
        <input
          class="input is-size-6-mobile is-uppercase"
          v-model.trim="vin"
          type="text"
          :disabled="hasData"
          name="vin"
        />
      </div>
      <div class="column">
        <label class="has-text-weight-semibold">Mileage</label>
        <input
          class="input is-size-6-mobile"
          v-model.number="mileage"
          type="number"
          max="500000"
          min="0"
          name="mileage"
        />
      </div>
    </div>
    <div class="buttons is-right">
      <button
        :class="{ 'is-loading': $wait.is('manualLoading') }"
        class="button is-rounded is-soft"
        @click="checkMileage"
        :disabled="!valid || $wait.is('manualLoading')"
      >
        Check mileage
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VmcInput',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({ mileage: null, vrm: '', vin: '' }),
  mounted() {
    this.vrm = this.data?.vrm || ''
    this.vin = this.data?.vin || ''
    this.mileage = this.data?.mileage || null
  },
  computed: {
    valid() {
      return this.vrm || this.vin
    },
    hasData() {
      return !!Object.keys(this.data).length
    }
  },
  methods: {
    checkMileage() {
      const { vrm, vin, mileage } = this
      this.$emit('check-mileage', { vrm, vin, mileage })
    }
  }
}
</script>
